import * as React from 'react';
import { useIMask } from 'react-imask';
import { mergeRefs } from 'react-merge-refs';
import {
  focusRingClassNames,
  inputClassNames,
} from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';
import { ColorPicker } from '~/modules/ui/primitives/color-picker';
import { Dollar2Icon, PercentageIcon } from '~/modules/ui/primitives/icon';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/modules/ui/primitives/popover';
import {
  ToggleGroup,
  ToggleGroupItem,
} from '~/modules/ui/primitives/toggle-group';

function InputWithIconWrapper({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn(
        'relative w-full',
        '[&>svg]:absolute [&>svg]:top-2.5 [&>svg]:left-3 [&>svg]:size-5 [&>svg]:text-foreground-secondary',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  WrapperProps?: Omit<
    React.ComponentPropsWithoutRef<typeof InputWithIconWrapper>,
    'children'
  >;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, children, type = 'text', WrapperProps, ...props }, ref) => {
    const Comp = children ? InputWithIconWrapper : React.Fragment;
    return (
      <Comp {...WrapperProps}>
        {children && children}
        <input
          type={type}
          className={cn(
            inputClassNames,
            children ? 'pl-10' : undefined,
            className,
          )}
          ref={ref}
          {...props}
        />
      </Comp>
    );
  },
);
Input.displayName = 'Input';

interface MaskedInputProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Input>, 'type'> {
  // biome-ignore lint/suspicious/noExplicitAny: ok
  mask: any;
  unmask?: boolean;
  radix?: string;
  thousandsSeparator?: string;
  scale?: number;
  normalizeZeroes?: boolean;
  padFractionalZeros?: boolean | number;
}

const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  (
    {
      className,
      mask,
      unmask = true,
      onBlur,
      onFocus,
      onChange,
      radix,
      thousandsSeparator,
      scale,
      normalizeZeroes,
      padFractionalZeros,
      value: _value,
      ...props
    },
    forwardedRef,
  ) => {
    const { ref, maskRef, setValue } = useIMask(
      {
        mask,
        radix,
        thousandsSeparator,
        scale,
        normalizeZeroes,
        // padFractionalZeros, // TODO - GSIMARD: https://tola--hq.slack.com/archives/C02MV5ZBGAW/p1736804720789569
        definitions: {
          h: [/^[0-9a-fA-F]{0,6}$/i],
        },
      },
      {
        onAccept: (value, _maskRef, e) => {
          if (onChange && e) {
            onChange(maybeCloneEvent(e) as React.ChangeEvent<HTMLInputElement>);
          }
        },
      },
    );

    React.useEffect(() => {
      if (typeof _value === 'string') {
        setValue(_value);
      }
    }, [_value, setValue]);

    function maybeCloneEvent(
      event:
        | InputEvent
        | React.ChangeEvent<HTMLInputElement>
        | React.FocusEvent<HTMLInputElement>,
    ) {
      if (!unmask) {
        return event;
      }
      return {
        ...event,
        target: { ...event.target, value: maskRef.current?._unmaskedValue },
      } as unknown;
    }

    return (
      <Input
        type="text"
        ref={mergeRefs([forwardedRef, ref])}
        className={cn(className)}
        onBlur={(e) =>
          onBlur &&
          onBlur(maybeCloneEvent(e) as React.FocusEvent<HTMLInputElement>)
        }
        onFocus={(e) =>
          onFocus &&
          onFocus(maybeCloneEvent(e) as React.FocusEvent<HTMLInputElement>)
        }
        defaultValue={_value}
        {...props}
      />
    );
  },
);
MaskedInput.displayName = 'MaskedInput';

const ColorInput = React.forwardRef<
  HTMLInputElement,
  Omit<React.ComponentPropsWithoutRef<typeof Input>, 'value'> & {
    value?: string;
    defaultValue?: string;
  }
>(({ className, ...props }, forwardedRef) => {
  return (
    <MaskedInput
      ref={forwardedRef}
      mask="{#}hhhhhh"
      placeholder="#000000"
      className={cn('tabular-nums', className)}
      {...props}
    />
  );
});
ColorInput.displayName = 'ColorInput';

function ColorInputPopover({
  value,
  onChange,
  disabled,
}: Omit<React.ComponentPropsWithoutRef<typeof ColorPicker>, 'color'> & {
  disabled?: boolean;
  value: string;
}) {
  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          focusRingClassNames,
          'absolute top-2.5 left-3 size-5 rounded-sm bg-surface-muted',
          'disabled:cursor-not-allowed',
        )}
        style={{ backgroundColor: value }}
        disabled={disabled}
      >
        <div className="sr-only">Pick color</div>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="start"
        sideOffset={16}
        alignOffset={-12}
        className="w-56 overflow-visible p-2"
      >
        <ColorPicker color={value} onChange={onChange} />
      </PopoverContent>
    </Popover>
  );
}

const currencyMask = [
  {
    mask: Number,
    scale: 2,
    thousandsSeparator: ',',
    radix: '.',
    mapToRadix: [','],
    padFractionalZeros: true,
  },
];

interface CurrencyInputProps
  extends Omit<React.ComponentPropsWithoutRef<typeof MaskedInput>, 'mask'> {
  mask?: unknown;
}

const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ className, mask, ...props }, forwardedRef) => {
    return (
      <MaskedInput
        ref={forwardedRef}
        mask={mask ?? currencyMask}
        placeholder="0.00"
        className={cn('tabular-nums', className)}
        {...props}
      />
    );
  },
);
CurrencyInput.displayName = 'CurrencyInput';

const percentageMask = [
  { mask: '' },
  {
    mask: 'num%',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 7,
        min: 0,
        max: 100,
        radix: '.',
        mapToRadix: [','],
      },
    },
  },
];

const PercentageInput = React.forwardRef<
  HTMLInputElement,
  Omit<React.ComponentPropsWithoutRef<typeof Input>, 'value'> & {
    value?: string;
    defaultValue?: string;
  }
>(({ className, ...props }, forwardedRef) => {
  return (
    <MaskedInput
      ref={forwardedRef}
      mask={percentageMask}
      placeholder="0%"
      className={cn('tabular-nums', className)}
      {...props}
    />
  );
});
PercentageInput.displayName = 'PercentageInput';

export type CurrencyOrPercentage = 'currency' | 'percentage';

type OmittedInputProps = Omit<
  React.ComponentPropsWithoutRef<typeof Input>,
  'value' | 'placeholder' | 'onChange'
>;

interface CurrencyOrPercentageInputProps extends OmittedInputProps {
  value?: string;
  defaultMode?: CurrencyOrPercentage;
  onValueChange?: ({
    mode,
    value,
  }: { mode: CurrencyOrPercentage; value: string }) => void;
}

const CurrencyOrPercentageInput = React.forwardRef<
  HTMLInputElement,
  CurrencyOrPercentageInputProps
>(
  (
    {
      className,
      defaultMode = 'percentage',
      value = '',
      onValueChange,
      children,
      ...props
    },
    forwardedRef,
  ) => {
    const [inputValue, setInputValue] = React.useState(value);
    const [currentMode, setCurrentMode] =
      React.useState<CurrencyOrPercentage>(defaultMode);

    const inputMask =
      currentMode === 'currency' ? currencyMask : percentageMask;
    const inputPlaceholder = currentMode === 'currency' ? '0.00' : '0%';

    function handleModeOrValueChange(
      mode: CurrencyOrPercentage,
      value: string,
    ) {
      setCurrentMode(mode);
      setInputValue(value);
      onValueChange?.({ mode, value });
    }

    React.useEffect(() => {
      // Update internal state if the component is controlled
      handleModeOrValueChange(currentMode, value);
    }, [currentMode, value]);

    return (
      <MaskedInput
        ref={forwardedRef}
        mask={inputMask}
        placeholder={inputPlaceholder}
        className={cn('tabular-nums', className)}
        value={inputValue}
        onChange={(e) => {
          handleModeOrValueChange(currentMode, e.target.value);
        }}
        {...props}
      >
        <ToggleGroup
          type="single"
          variant="solid"
          size="sm"
          value={currentMode}
          className="absolute inset-y-0 right-1.5"
          onValueChange={(value) => {
            if (!value) {
              return;
            }
            handleModeOrValueChange(value as CurrencyOrPercentage, inputValue);
          }}
        >
          <ToggleGroupItem
            className="px-0"
            value="currency"
            aria-label="Use amount"
          >
            <Dollar2Icon />
          </ToggleGroupItem>
          <ToggleGroupItem
            className="px-0"
            value="percentage"
            aria-label="Use percentage"
          >
            <PercentageIcon />
          </ToggleGroupItem>
          {children}
        </ToggleGroup>
      </MaskedInput>
    );
  },
);
CurrencyOrPercentageInput.displayName = 'CurrencyOrPercentageInput';

export {
  Input,
  MaskedInput,
  ColorInput,
  ColorInputPopover,
  CurrencyInput,
  PercentageInput,
  CurrencyOrPercentageInput,
};
