import { MotionGlobalConfig } from 'framer-motion';
import { gsap } from 'gsap';
import { useEffect } from 'react';

export const useReducedMotion = () => {
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    // Function to handle the preference check
    const handleReduceMotionChanged = (event: MediaQueryListEvent) => {
      if (event.matches) {
        gsap.globalTimeline.pause();
        MotionGlobalConfig.skipAnimations = true;
      } else {
        gsap.globalTimeline.resume();
        MotionGlobalConfig.skipAnimations = false;
      }
    };

    mediaQuery.addEventListener('change', handleReduceMotionChanged);
    handleReduceMotionChanged(mediaQuery as unknown as MediaQueryListEvent);
    return () => {
      mediaQuery.removeEventListener('change', handleReduceMotionChanged);
    };
  }, []);
};
