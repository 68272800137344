import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { CommonFieldProps } from '~/modules/ui/fields/common-field-props';
import {
  FormControl,
  FormDescription,
  FormField,
  FormHint,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/modules/ui/primitives/form';
import { Input } from '~/modules/ui/primitives/input';
import { HStack, VStack } from '~/modules/ui/primitives/stack';
type InputFieldProps = CommonFieldProps &
  ComponentPropsWithoutRef<typeof Input>;

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const {
      description,
      label,
      name,
      helperLabel,
      hideErrors = false,
      ...rest
    } = props;
    const form = useFormContext();

    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <VStack gap="2">
              {(label || helperLabel) && (
                <HStack alignItems="center">
                  {label && <FormLabel>{label}</FormLabel>}
                  {helperLabel && <FormHint>{helperLabel}</FormHint>}
                </HStack>
              )}
              <FormControl>
                <Input {...rest} {...field} ref={ref} />
              </FormControl>
              {description && <FormDescription>{description}</FormDescription>}
              {!hideErrors && <FormMessage />}
            </VStack>
          </FormItem>
        )}
      />
    );
  },
);
